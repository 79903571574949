
// 密码校验
export const password = (rule: object, value: string, callback: Function) => {
  const reg = /^[a-zA-Z0-9]{6,10}$/
  if (!reg.test(value)) {
    callback(new Error('请输入6-10位大、小写英文字母或数字'))
  } else {
    callback()
  }
}

// 密码校验(非必填)
export const passwordNot = (rule: object, value: string, callback: Function) => {
  const reg = /^[a-zA-Z0-9]{6,10}$/
  if (value && !reg.test(value)) {
    callback(new Error('请输入6-10位大、小写英文字母或数字'))
  } else {
    callback()
  }
}

// 两位小数
export const twoDecimal = (rule: object, value: string, callback: Function) => {
  const reg = /^([1-9][0-9]*|[0-9])(\.\d{0,2})?$/
  if (!reg.test(value)) {
    return callback(new Error('请输入两位以内的小数或整数'))
  } else {
    return callback()
  }
}

// 手机格式校验
export const phone = (rule: object, value: string, callback: Function) => {
  const reg = /^\d{11}$/
  if (value && !reg.test(value)) {
    return callback(new Error('请输入正确手机号码'))
  } else {
    return callback()
  }
}

// 邮箱格式效验
export const email = (rule: object, value: string, callback: Function) => {
  const reg = /^[A-z\d_-]+@[A-z\d_-]+\.[A-z\d_-]+$/
  if (value && !reg.test(value)) {
    return callback(new Error('邮箱格式错误'))
  } else {
    return callback()
  }
}

// 正整数效验
export const pInteger = (rule: object, value: string, callback: Function) => {
  const reg = /^[1-9][0-9]*$/
  if (value != null && value !== '') {
    if (!reg.test(value)) {
      return callback(new Error('请输入正整数值'))
    } else {
      return callback()
    }
  } else {
    return callback()
  }
}


































import { Vue, Component } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { twoDecimal } from '@/utils/validate'
import { DeviceType, DeviceModel } from '@/types/device'

@Component
export default class DeviceAdd extends Vue {
  private deviceTypeList: DeviceType[] = []
  private deviceModelList: DeviceModel[] = []
  private submitShow = false
  private deviceInfo = {
    deviceTypeId: '',
    deviceNumber: '',
    deviceModel: ''
  }

  private rules = {
    deviceTypeId: [
      { required: true, message: '请选择设备类型', trigger: 'change' }
    ],
    deviceNumber: [
      { required: true, message: '请输入设备串号', trigger: 'change' }
    ],
    deviceModel: [
      { required: true, message: '请选择设备型号', trigger: 'change' }
    ],
    supplierId: [
      { required: true, message: '请选择供应商', trigger: 'change' }
    ],
    price: [
      { required: true, validator: twoDecimal, trigger: 'change' }
    ]
  }

  created () {
    this.getDeviceTypeList()
  }

  getDeviceTypeList () {
    this.$axios.get(this.$apis.device.selectDeviceTypeByList).then(res => {
      this.deviceTypeList = res.list
    })
  }

  typeChange (id: string) {
    this.deviceInfo.deviceModel = ''
    const data: any = this.deviceTypeList.find((item: DeviceType) => {
      return item.deviceTypeId === id
    })
    this.deviceModelList = data ? data.deviceModels : []
  }

  addDevice () {
    this.submitShow = true
    this.$axios.post(this.$apis.device.insertDevice, this.deviceInfo).then(() => {
      this.$message.success('设备添加成功')
      this.$router.back()
    }).finally(() => {
      this.submitShow = false
    })
  }

  submit (formName: string) {
    (this.$refs[formName] as ElForm).validate(valid => {
      if (valid) {
        this.addDevice()
      }
    })
  }
}
